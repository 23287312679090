import { graphql } from "gatsby"
import React from "react"

import { Banner, TeamMembers } from "../components/elements"
import { Layout } from "../components/layouts/Layout"
import { Wrapper } from "../components/utilities"

export const getFileNode = ({ edges, name }) =>
  edges.find(item => item.node.relativePath === name)

const staff = ({ edges }) => [
  {
    name: "Kaitlyn Mauder",
    title: "Esthetician/ Owner",
    bio: () => (
      <>
        Kaitlyn is our Esthetician and Owner of the salon! She is passionate
        about skincare and lashes. Her favorite part of her job is seeing her
        clients reactions after each service! Handing over the mirror and
        watching their expressions is nothing but the best! Fun fact about
        Kaitlyn; she loves pickles and traveling! Check out her work through
        Instagram{" "}
        <a href="https://www.instagram.com/skinbykaitlyn" target="_blank">
          @skinbykaitlyn!
        </a>{" "}
        Call or Text <a href="tel:4192614162">419-261-4162</a> to book an
        appointment.
      </>
    ),
    picture: getFileNode({ edges, name: "kaitlyn.2022.jpg" }).node
      .childImageSharp.fluid.src,
  },
  {
    name: "McKenna Ryan",
    title: "Hairstylist/Men's Care Expert",
    bio: () => (
      <>
        McKenna’s goal is to make her clients feel and look their absolute best!
        Her talent extends to both men’s hair and beards, and women’s hair and
        make up. McKenna is very passionate about her profession, while creating
        a relaxing, comfortable environment for all of her customers. She is a
        talented and outgoing young stylist! Check out her work through
        Instagram{" "}
        <a href="https://www.instagram.com/mckenna_cuts" target="_blank">
          @mckenna_cuts
        </a>{" "}
        Call ot text <a href="tel:4193462996">419-346-2996</a> to book an
        appointment.
      </>
    ),
    picture: getFileNode({ edges, name: "mckenna.2022.jpg" }).node
      .childImageSharp.fluid.src,
  },
  {
    name: "Melissa Huff",
    title: "Hair Stylist/ Make-up Artist ",
    bio: () => (
      <>
        Melissa specializes in blonding service, and loves to perform any
        haircolor service. Her goal is to make each and every client feel
        confident and enhance their natural beauty. Melissa also loves to
        perform hair and makeup services for bridal parties. Melissa has been a
        licensed cosmetologist for over 5 years and loves taking continuing
        education classes to further advance her skills. Her favorite color is
        purple and she spends most of her free time with her two dogs. Check out
        her work on Instagram{" "}
        <a href="https://www.instagram.com/hairbymelhuff" target="_blank">
          @hairbymelhuff
        </a>{" "}
        Call or text{" "}
        <a href="tel:4195754958" target="_blank">
          419-575-4958
        </a>{" "}
        to book an appointment.
      </>
    ),
    picture: getFileNode({ edges, name: "melissa.2022.jpg" }).node
      .childImageSharp.fluid.src,
  },
  {
    name: "Maddi Smith",
    title: "Hairstylist",
    bio: () => (
      <>
        Maddi specializes in blonding and hair cutting services, but loves to
        perform all coloring services. Her goal is to make every client feel
        more beautiful walking out than they did coming in! She likes to perform
        makeup services as well. She’s always down for a challenge. She has been
        licensed for 2 years and is always looking for ways to continue growing
        her education. She is a boy mom and loves all animals! She spends most
        of her free time enjoying nature and being outside with her family. You
        can check out her work on Instagram{" "}
        <a
          href="https://www.instagram.com/_beautybymaddisaige_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          @_beautybymaddisaige_
        </a>{" "}
        Call or text at <a href="tel:4195408929">(419)-540-8929</a> to book an
        appointment.
      </>
    ),
    picture: getFileNode({ edges, name: "Maddi.jpg" }).node.childImageSharp
      .fluid.src,
  },
  {
    name: "Kristi O'bryan",
    title: "Hairstylist/ Make-Up Artist",
    bio: () => (
      <>
        Kristi specializes in blonding, color, and men's hair. She loves iced
        coffee and warm sunny days. Her goal is to create looks that make
        clients look and feel their best. She is also a make-up artist and loves
        to style bridal parties. Kristi specializes in hair extensions and has
        been in the industry for 8 years! Check out her work through Instagram{" "}
        <a
          href="https://www.instagram.com/yourhairgirl_kristi/"
          target="_blank"
        >
          @yourhairgirl_kristi
        </a>{" "}
        Call or text <a href="tel:5672023401">567-202-3401</a> to book an
        appointment.
      </>
    ),
    picture: getFileNode({ edges, name: "kristi-2024.jpg" }).node
      .childImageSharp.fluid.src,
  },
]

const Staff = ({ data }) => (
  <Layout title="Our Staff">
    <Banner title="Meet Our Team" />
    <Wrapper>
      <TeamMembers staff={staff({ edges: data.allFile.edges })} />
    </Wrapper>
  </Layout>
)

export default Staff

export const query = graphql`
  query StaffQuery {
    allFile(
      filter: {
        relativePath: {
          in: [
            "kaitlyn.2022.jpg"
            "brooklyn.jpg"
            "mckenna.2022.jpg"
            "melissa.2022.jpg"
            "taylor.2022.jpg"
            "Maddi.jpg"
            "kristi-2024.jpg"
          ]
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200) {
              src
            }
          }
        }
      }
    }
  }
`
