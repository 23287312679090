import React from "react"
import { graphql } from "gatsby"

import { AlignCenter } from "../components/utilities"
import { Layout } from "../components/layouts/Layout"
import {
  Banner,
  Section,
  PortfolioSection,
  Signature,
  Hero,
} from "../components/elements"

import { getFileNode } from "./staff"

const IndexPage = props => {
  const { data } = props
  const edges = data.allFile.edges
  const teamPhoto = getFileNode({ edges, name: "team.jpg" }).node
    .childImageSharp.fluid.src

  return (
    <Layout title="Welcome">
      <Hero img={teamPhoto} />
      <Section maxWidth="600px">
        <AlignCenter>
          <p>
            KN Beauty Co. is a salon located in Rossford, OH that provides all
            services, including: Hair, Nails, Skin, Lashes, and Massage. Every
            professional is their own independent boss that focuses on modern,
            effortless techniques to achieve your goals. We strive to make every
            guest feel comfortable and welcomed when arriving.
          </p>
          <br />
          <Signature />
        </AlignCenter>
      </Section>
      <Banner />
      <PortfolioSection
        title="Portfolio"
        tag="An eye for detail makes our work excellent"
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allFile(filter: { relativePath: { in: ["team.jpg"] } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    }
  }
`
